// app/components/dropdown/component_controller.js
import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage";
import { useTransition } from 'stimulus-use';

export default class extends Controller {
	DEBUG_ENABLED = true

	static targets = [
		"filegallery", 
		"image_gallery",
		"file_gallery",
		"overlay", // YES
		"imagetemplate",
		"filetemplate",
		"gallery",
		"empty",
		"fileupload", // YES
		"image_fileupload", // YES
		"file_fileupload", // YES
		"header",
		"file_good", 
		"file_unsupported",
		"target",
		"template"
	];

	static values = { 
		imageUrl: String, 
		fileUrl: String,
		imageExtensions: Array,
		modelExtensions: Array,
		preloadedImageCount: Number,
		preloadedFileCount: Number,
	};

	connect() {
		this.debug("connect()")
		this.debug(`Image file extensions: ${this.imageExtensionsValue}`)
		this.debug(`Model file extensions: ${this.modelExtensionsValue}`)
		this.debug(`Image Upload URL: ${this.imageUrlValue}`)
		this.debug(`Modile File Upload URL: ${this.fileUrlValue}`)
		this.debug(`Preloaded Image Count: ${this.preloadedImageCountValue}`)
		this.debug(`Preloaded Model File Count: ${this.preloadedFileCountValue}`)
	}

	debug(message) {
		if (this.DEBUG_ENABLED) {
			console.log("UPLOADER COMPONENT: ", message)
		}
	}

	// DRAG EVENTS

	onDragOver(event) {
		this.debug("onDragOver()")
		event.preventDefault();
		this.overlayTarget.classList.add('draggedover');
	}

	onDragLeave() {
		this.debug("onDragLeave()")
		this.overlayTarget.classList.remove('draggedover');
	}

	// DROP EVENTS

	onDrop(event) {
		this.debug("onDrop()")
		event.preventDefault();

		for (const file of event.dataTransfer.files) {
			this.addFile(file);
			this.overlayTarget.classList.remove("draggedover");
		}
	}

	// CLICK EVENTS

	click(event) {
		event.preventDefault();
		this.debug("Upload a file was clicked")
		this.fileuploadTarget.click() // Click the file upload input to toggle the file selector dialogue
	}

	fileUploadClick(event) {
		event.preventDefault();
		this.debug("Add File was clicked")
		this.file_fileuploadTarget.click() // Click the file upload input to toggle the file selector dialogue
	}

	imageUploadClick(event) {
		event.preventDefault();
		this.debug("Add Image was clicked")
		this.image_fileuploadTarget.click() // Click the image file upload input to toggle the file selector dialogue
	}	

	delete(event) {
		this.debug("delete()")
	 	event.preventDefault();

		const wrapper = event.target.closest('.upload')

		if (wrapper.dataset.newRecord === 'true') {
    	wrapper.remove()
    } else {
    	wrapper.classList.add("hidden")
      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = '1'
    }

		this.toggleImagePanel();
		this.toggleModelPanel();		
	}

	filechange(event) { // When files are added via the file selector dialogue
		this.debug("filechange()")
		for (const file of event.target.files) {
			this.addFile(file);
		}
	}

	toggleImagePanel() {
		if (document.querySelectorAll('[data-object-type=image]:not(.hidden)').length == 0 ) {
			this.image_galleryTarget.classList.add('hidden')
		} else {
			this.image_galleryTarget.classList.remove('hidden')
		}
	}

	toggleModelPanel() {
		if (document.querySelectorAll('[data-object-type=file]:not(.hidden)').length == 0 ) {
			this.file_galleryTarget.classList.add('hidden')
		} else {
			this.file_galleryTarget.classList.remove('hidden')
		}
	}

	addFile(file) {
		this.debug("addFile()")

		// Do we accept this file type?
		if (!this.hasExtension(file.name, (this.imageExtensionsValue.concat(this.modelExtensionsValue)) )) {
			this.headerTarget.classList.add("bg-red-300")
			this.file_goodTarget.classList.add("hidden")
			this.file_unsupportedTarget.classList.remove("hidden")
			setTimeout(() => {
				this.headerTarget.classList.remove("bg-red-300")
				this.file_goodTarget.classList.remove("hidden")
				this.file_unsupportedTarget.classList.add("hidden")
			}, 2000);
			return
		}

		const isImage = file.type.match("image.*"), objectURL = URL.createObjectURL(file);

		if (isImage) {
			const clone = this.imagetemplateTarget.content.cloneNode(true)
			const identifier = new Date().getTime().toString()

			clone.querySelector(".upload").id = identifier //objectURL;
			//clone.querySelector(".delete").dataset.target = objectURL;
			//clone.querySelector(".delete").dataset.identifier = `image_${this.IMAGE_FILE_COUNT}`;
			// clone.querySelector(".image_input").name = `bit[images_attributes][${this.IMAGE_FILE_COUNT}][imagefile]`;
			// clone.querySelector(".delete").dataset.objecttype = 'image';

			isImage &&
				Object.assign(clone.querySelector("img"), {
					src: objectURL,
					alt: file.name
				});

			// // Add the image template to the gallery
			// this.image_galleryTarget.querySelector('.grid').prepend(clone);
			// this.debug(`Image File Count: ${this.IMAGE_FILE_COUNT}`)

			this.image_galleryTarget.querySelector('.grid').prepend(clone);

			this.toggleImagePanel();
			this.uploadFile("images", file, identifier); 
		} else {

			const clone = this.templateTarget.content.cloneNode(true)
			const identifier = new Date().getTime().toString()

			clone.querySelector(".filename").textContent = file.name;

			clone.querySelector(".scale").id = clone.querySelector(".scale").id.replace(/NEW_RECORD/g, identifier)
			clone.querySelector(".scale").name = clone.querySelector(".scale").name.replace(/NEW_RECORD/g, identifier)
		//	clone.id = '12345'

			clone.querySelector(".upload").id = identifier

			// clone.querySelector("li").id = objectURL;
			//clone.querySelector(".uploaded-file").id = objectURL;
			//clone.querySelector(".delete").dataset.target = objectURL;
			//clone.querySelector(".delete").dataset.objecttype = 'model';
			clone.querySelector(".size").textContent =
				file.size > 1024
					? file.size > 1048576
						? Math.round(file.size / 1048576) + "mb"
						: Math.round(file.size / 1024) + "kb"
					: file.size + "b";

			//this.file_galleryTarget.querySelector('tbody').prepend(clone);

			//clone.replace(/NEW_RECORD/g, new Date().getTime().toString())
			this.file_galleryTarget.querySelector('tbody').prepend(clone);
			//this.targetTarget.insertAdjacentHTML('beforebegin', content)

			this.toggleModelPanel();
			this.uploadFile("bit_file", file, identifier); 
		}

	}

	uploadFile(type, file, identifier) {
		this.debug(`Uploading file: ${type}`)
		const upload = type == "images" ? new DirectUpload(file, this.imageUrlValue) : new DirectUpload(file, this.fileUrlValue);

    upload.create((error, blob) => {
      if (error) {
        this.debug("Upload Error");
      } else {
				const hiddenField = document.createElement('input')
      	hiddenField.setAttribute("type", "hidden");
	      hiddenField.setAttribute("value", '');

				// Add the form fields to the page.
				if (type == 'images') {
					hiddenField.value = blob.signed_id
					hiddenField.name = `bit[images_attributes][${identifier}][imagefile]`
				} else {
					hiddenField.value = blob.signed_id
					hiddenField.name = `bit[bit_files_attributes][${identifier}][bitfile]`
				}
    	  document.getElementById(identifier).appendChild(hiddenField)
    	}
    });
  }

	hasExtension(fileName, exts) {
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
	}

}