import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

export default class extends Controller {
	initialize () {
    this.hide = this.hide.bind(this)
  }

	connect() {
		useTransition(this)
		this.enter()
		this.timeout = setTimeout(this.hide, 5000)
	}		

	async hide() {
		if (this.timeout) {
      clearTimeout(this.timeout)
    }

    await this.leave()

    this.element.remove()
	}

}
